<template>
    <div class="">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 日志记录</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container" style="padding-top: 5px">
            <el-tabs v-model="base">
                <el-tab-pane label="登陆日志" name="first" v-if="hasAuth('GetLoginLog')">
                    <div class="handle-box" v-if="base === 'first'">
                        <el-popover placement="top-start" trigger="hover" content="用户">
                            <template #reference>
                            <el-input v-model="loginQuery.name" placeholder="用户" class="handle-input" :clearable="true"></el-input>
                            </template>
                        </el-popover>
                        <el-date-picker
                                v-model="datetime"
                                type="datetimerange"
                                :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 1, 1, 23, 59, 59)]"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                        <el-button type="primary" icon="el-icon-search" @click="getLoginLog()">搜索</el-button>
                    </div>
                    <el-table
                            :data="loginData"
                            border
                            class="table"
                            :height="common.calcTableHeight(380)"
                            header-cell-class-name="table-header">
                        <el-table-column prop="id" label="序号" width="55" align="center">
                            <template #default="scope">
                                <span>{{scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="userId" label="用户" align="center">
                            <template #default="scope">
                                {{findArray(users,scope.row.userId)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="ip" label="IP" align="center"></el-table-column>
                        <el-table-column prop="url" label="地址" align="center"></el-table-column>
                        <el-table-column prop="address" label="归属地" align="center"></el-table-column>
                        <el-table-column prop="createDate" label="登陆时间" align="center">
                            <template #default="scope">
                                {{scope.row.createDate.toDate('yyyy-MM-dd HH:mm:ss')}}
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                                background
                                layout="total, prev, pager, next"
                                :current-page="loginQuery.page"
                                :page-size="loginQuery.rows"
                                :total="loginPageTotal"
                                @current-change="handleLoginChange"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="操作日志" name="second" v-if="hasAuth('GetOperationLog')">
                    <template v-if="base === 'second'">
                        <div class="handle-box" v-if="base === 'second'">
                            <el-popover placement="top-start" trigger="hover" content="类型">
                                <template #reference>
                                <el-select v-model="operationQuery.type" placeholder="类型" class="handle-select">
                                    <el-option key="" label="所有" value=""></el-option>
                                    <el-option :key="item.id" :label="item.name" :value="item.id"
                                    v-for="item in types"></el-option>
                                </el-select>
                                </template>
                            </el-popover>
                            <el-popover placement="top-start" trigger="hover" content="用户">
                                <template #reference>
                                <el-input v-model="operationQuery.name" placeholder="用户" class="handle-input" :clearable="true"></el-input>
                                </template>
                            </el-popover>
                            <el-date-picker
                                    v-model="datetime"
                                    type="datetimerange"
                                    :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 1, 1, 23, 59, 59)]"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                            <el-input v-model="operationQuery.key" placeholder="操作详情" class="handle-input" :clearable="true"></el-input>
                            <el-button type="primary" icon="el-icon-search" @click="getOperationLog()">搜索</el-button>
                        </div>
                        <el-table
                                :data="operationData"
                                border
                                :height="common.calcTableHeight(380)"
                                class="table"
                                header-cell-class-name="table-header">
                            <el-table-column prop="id" label="序号" width="55" align="center">
                                <template #default="scope">
                                    <span>{{scope.$index + 1}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="type" label="类型" width="150px" align="center">
                                <template #default="scope">
                                    {{findArray(types,scope.row.type)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="operationUserId" label="操作用户" width="100px" align="center">
                                <template #default="scope">
                                    {{findArray(users,scope.row.operationUserId)}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="操作详情" align="center"></el-table-column>
                            <el-table-column prop="createDate" label="操作时间" width="180px" align="center">
                                <template #default="scope">
                                    {{scope.row.createDate.toDate('yyyy-MM-dd HH:mm:ss')}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                    background
                                    layout="total, prev, pager, next"
                                    :current-page="operationQuery.page"
                                    :page-size="operationQuery.rows"
                                    :total="operationPageTotal"
                                    @current-change="handleOperationChange"
                            ></el-pagination>
                        </div>
                    </template>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import api from "../../utils/api";

    export default {
        name: 'tabs',
        data() {
            return {
                common:common,
                operationQuery: {
                    page:1,
                    rows:20
                },
                loginQuery: {
                    page:1,
                    rows:20
                },
                base: this.hasAuth('GetLoginLog')?'first':'second',
                loginData: [],
                users: [],
                operationData: [],
                loginPageTotal: 0,
                operationPageTotal: 0,
                datetime: [],
                types: []
            }
        },
        created() {
            this.getOpType();
            if(this.hasAuth('GetLoginLog')){
                this.getLoginLog();
            }else{
                this.getOperationLog();
            }
        },
        methods: {
            //获取操作类型
            getOpType(){
                http.post("/log/listOperationType").then(data => {
                    data = data.data;
                    if (data) {
                        this.types = data;
                    }

                })
            },
            // 获取登陆日志
            getLoginLog() {
                this.loginQuery.startDate = null;
                this.loginQuery.endDate = null;
                if (this.datetime && this.datetime.length === 2) {
                    this.loginQuery.startDate = this.datetime[0].getTime();
                    this.loginQuery.endDate = this.datetime[1].getTime();
                }
                http.post("/log/listLoginLog", this.loginQuery).then(data => {
                    data = data.data;
                    if (data) {
                        this.loginData = data.rows;
                        this.loginPageTotal = data.total;
                        this.getUsername(this.loginData, "userId");
                    }

                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            // 获取操作日志
            getOperationLog() {
                http.post("/log/listOperationLog", this.operationQuery).then(data => {
                    data = data.data;
                    if (data) {
                        this.operationData = data.rows;
                        this.operationPageTotal = data.total;
                        this.getUsername(this.operationData, "operationUserId");
                    }

                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            //获取用户名
            getUsername(data, key) {
                if (data) {
                    let ids = [];
                    for (let i = 0; i < data.length; i++) {
                        ids.push2(data[i][key]);
                    }
                    if (ids.length > 0) {
                        api.getUser(ids, (data) => {
                            this.users = data;
                        })
                    }
                }
            },
            // 分页导航
            handleLoginChange(val) {
                this.loginQuery.page = val;
                this.getLoginLog();
            },
            // 分页导航
            handleOperationChange(val) {
                this.operationQuery.page = val;
                this.getOperationLog();
            },
            //检查权限
            hasAuth(auth) {
                return common.hasAuth(auth);
            }
        },
        watch: {
            base: function (val) {
                if (val === "first") {
                    this.getLoginLog();
                } else if (val === "second") {
                    this.getOperationLog();
                }
            }
        }
    }

</script>

<style>
    .handle-box {
        margin-bottom: 20px;
    }


    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .handle-select {
        width: 120px;
    }

    .tree-input input {
        height: 30px;
        padding: 0;
        margin: 0;
    }
</style>